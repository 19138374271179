<aside class="bg-surface-900 flex h-full w-16 flex-col items-center justify-between py-4">
  <div>
    <!-- Noova logo -->
    <a class="rail-link" [routerLink]="['/energy']">
      <svg-icon key="noova-logo" size="xxl"></svg-icon>
    </a>

    <p-divider styleClass="appDivider"></p-divider>

    <!-- Customer select -->
    @if (canSelectCustomer()) {
      <a
        class="rail-link"
        [ngClass]="{ active: isActive('/select-customer') }"
        [routerLink]="['/select-customer']"
        pTooltip="{{ selectCustomerTooltip() }}"
        [showDelay]="200">
        <svg-icon key="switch-arrows"></svg-icon>
      </a>
      <p-divider></p-divider>
    }

    <!-- Primary nav -->
    <nav class="flex flex-col items-center gap-1">
      @for (link of primaryNavLinks(); track link.path) {
        <a
          class="rail-link"
          [ngClass]="{ active: isActive(link.path) }"
          [routerLink]="[link.path]"
          [queryParamsHandling]="'merge'"
          [pTooltip]="link.tooltip | translate"
          [showDelay]="200">
          <svg-icon [key]="link.icon"></svg-icon>
        </a>
      }
    </nav>
  </div>

  <div>
    <!-- Secondary nav -->
    <nav class="flex flex-col items-center gap-1">
      @for (link of secondaryNavLinks(); track link.path) {
        <a
          class="rail-link"
          [ngClass]="{ active: isActive(link.path) }"
          [routerLink]="[link.path]"
          [queryParamsHandling]="'merge'"
          [pTooltip]="link.tooltip | translate"
          [showDelay]="200">
          <svg-icon [key]="link.icon"></svg-icon>
        </a>
      }
    </nav>

    <p-divider></p-divider>

    <a
      class="avatar-link"
      [ngClass]="{ active: isActive('/profile') }"
      [routerLink]="['/profile']"
      [pTooltip]="'Your profile' | translate"
      [showDelay]="200">
      <p-avatar shape="circle" [label]="avatarInitials()"></p-avatar>
    </a>
  </div>
</aside>
